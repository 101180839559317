export const themeMui = {
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#9BC321'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#9BC321',
      dark: '#171717'
    },
    secondary: {
      main: '#FFF',
      dark: '#8F939B',
      lowTariff: '#1BBC9B',
      highTariff: '#DE3F1A',
      stepColor: '#C6C8D2',
      stepSubtitleColor: '#959BA2',
      blockBackgroundLight: '#292F32'
    }
  },
  typography: {
    h2: {
      fontSize: 20,
      fontWeight: 600
    },
    h3: {
      fontSize: 18,
      fontWeight: 600
    },
    h4: {
      fontSize: 16,
      fontWeight: 400
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    fontFamily: ['"Titillium Web", sans-serif'].join(',')
  }
};
