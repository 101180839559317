export const DEFAULT_FONT = '"Titillium Web"';

export const INSTALLATION_ONGOING_COLOR = '#3998F9';
export const NOT_CONNECTED_STATUS_COLOR = '#DE3F1A';
export const CONNECTED_COLOR = '#60CD27';

export const OK_COLOR = '#1BBC9B';
export const WARNING_COLOR = '#FF9E22';
export const ERROR_COLOR = '#ED6200';
export const NOT_CONNECTED_MONITORING_COLOR = '#DE3F1A';

export const PIE_CHART_COLORS = {
  countColor: '#FFFFFF',
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#c6c8d2',
  itemStyleColor: 'hsla(0,0%,100%,.5)',
  titleColor: '#c6c8d2',
  bgColor: '#292F32'
};

export const CIRCLE_CHART_COLORS = {
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#c6c8d2',
  itemStyleColor: 'hsla(0,0%,100%,.5)',
  titleColor: '#c6c8d2',
  bgColor: '#292F32',
  OVERALL_ACTIVE_COLOR: '#DE3F1A',
  ONE_YEAR_ACTIVE_COLOR: '#3998F9',
  ONE_MONTH_ACTIVE_COLOR: '#60CD27',
  OVERALL_EMPTY_COLOR: '#db422d1a',
  ONE_YEAR_EMPTY_COLOR: '#3998F91a',
  ONE_MONTH_EMPTY_COLOR: '#4CCD371a'
};

export const tooltip = {
  backgroundColor: '#292F32',
  style: {
    color: '#fff',
    fontSize: '16px'
  }
};
