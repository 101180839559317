import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Field } from 'redux-form';

import { renderTextField } from '../../../../components/ReduxFormFields';

export const TariffTextField = ({
  name,
  label = '',
  suffix = '',
  variant = 'h4',
  labelSx = {},
  parser = undefined,
  validate = undefined

}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      my: 1,
      gap: '15px'
    }}
  >
    <Typography
      sx={{
        textTransform: 'capitalize',
        color: 'secondary.main',
        minWidth: 'max-content',
        pr: 0.5,
        ...labelSx
      }}
      variant={variant}
    >
      {label}
    </Typography>

    <Field
      InputProps={{
        disableUnderline: true,
        sx: { backgroundColor: 'primary.dark', borderRadius: '4px', p: '4px 20px', maxWidth: '120px', border: '1px solid #FFFFFF80' }
      }}
      variant="standard"
      sx={{ color: 'secondary', fontSize: '14px' }}
      component={renderTextField}
      isFloat
      name={name}
      parse={parser}
      validate={validate}
    />
    {suffix && (
      <Typography
        sx={{
          color: 'secondary.main',
          minWidth: 'max-content'
        }}
        variant="h4"
      >
        {suffix}
      </Typography>
    )}
  </Box>
);

TariffTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  suffix: PropTypes.string,
  parser: PropTypes.func,
  validate: PropTypes.instanceOf(Array),
  labelSx: PropTypes.instanceOf(Object)
};
