import React from 'react';
import { Box } from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import PropTypes from 'prop-types';

export const Loader = ({ size = 70 }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <PuffLoader size={size} color="#9BC321" speedMultiplier={3} />
  </Box>
);

Loader.propTypes = {
  size: PropTypes.number
};
