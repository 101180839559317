import React from 'react';
import { Link } from 'react-router-dom';

import logo from './logos/logo.png';

import './index.scss';

/**
 * Renders Solar Manager Logo for all pages
 */
const Logo = () => (
  <div className="m-login__log">
    <Link to="/">
      <img src={logo} alt="logo_solar_manager" className="topBarLogo" />
    </Link>
  </div>
);

export default Logo;
