const replace = {
  changeSMID: 'Change Febesol ID',
  reqSMID: 'Febesol ID *',
  SMID: 'Febesol ID',
  SMIDTolltip: 'The Febesol ID is a unique identifier of your device. You find this ID on the device below the QR code.',
  enableSmId: 'Febesol ID',
  smidLabel: 'Febesol ID',
  confirmDeletingDesc: 'Confirm deleting of all data of this gateway by entering the Febesol ID. After pressing Confirm this process cannot be reversed.',
  confirmDeletingError: 'The ID does not match with the Febesol ID. The gateway is not deleted.'
};

export default replace;
