import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

export const RenderResendEmail = ({ email, sendEmailRequest, className }) => (
  <button
    type="button"
    onClick={() => sendEmailRequest(email)}
    className={`btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right ${className}`}
  >
    {i18n.t('resendEmail')}
  </button>
);

RenderResendEmail.propTypes = {
  email: PropTypes.string.isRequired,
  sendEmailRequest: PropTypes.func.isRequired,
  className: PropTypes.string
};

RenderResendEmail.defaultProps = {
  className: ''
};
