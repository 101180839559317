const replace = {
  changeSMID: 'Febesol ID ändern',
  reqSMID: 'Febesol ID *',
  SMID: 'Febesol ID',
  SMIDTolltip: 'Die Febesol ID ist eine eindeutige Kennung Ihres Gerätes. Sie finden diese ID auf dem Gerät unterhalb des QR-Codes.',
  enableSmId: 'Febesol ID',
  smidLabel: 'Febesol ID',
  confirmDeletingDesc: 'Bestätigen Sie das Löschen aller Daten dieses Gateways, indem Sie die Febesol -ID eingeben. Nach Bestätigung kann dieser Vorgang nicht rückgängig gemacht werden.',
  confirmDeletingError: 'Die ID stimmt nicht mit der Febesol-ID überein. Das Gateway wird nicht gelöscht.'
};

export default replace;
