const replace = {
  changeSMID: "Modifier l'ID du Febesol OEM",
  reqSMID: 'Febesol ID *',
  SMID: 'Febesol ID',
  SMIDTolltip: 'L\'ID Febesol est un identifiant unique de votre Febesol. Vous trouverez cet identifiant sur l\'appareil sous le code QR.',
  enableSmId: 'Febesol ID',
  smidLabel: 'Febesol ID',
  confirmDeletingDesc: 'Confirmez la suppression de toutes les données de cette passerelle en entrant dans le Febesol ID. Après confirmation, ce processus ne peut pas être inversé.',
  confirmDeletingError: 'L\'ID ne correspond pas à l\'ID du Febesol. La passerelle n\'est pas supprimée.'
};

export default replace;
